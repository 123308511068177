import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebpsupportService {

  constructor() { }

  private supported: boolean = false;

  private tested: boolean = false;

  public onTested: EventEmitter<boolean> = new EventEmitter<boolean>();

  public test() {
    const webP = new Image();
    webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
    webP.onload = webP.onerror = () => {
      this.supported = (webP.height === 2);
      this.tested = true;

      this.onTested.emit(this.supported);
    };
  }

  public isSupported(): boolean {
    return this.supported;
  }

  public isTested(): boolean {
    return this.tested;
  }


}
