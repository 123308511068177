import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CONST } from '../models/const';
import { Project, ProjectList } from '../models/projects';
import {
  AnimateEvent,
  LetinkApiService,
  LetinkEvent,
  RECEIVE_EVENT_NEXT,
  RECEIVE_EVENT_PREVIOUS,
  RECIEVE_ANIMATE_TOWARDS,
} from '../services/letink-api.service';
import {
  ThreeSixtyConfig,
  DisplayMode,
  ContainerMeasurements,
} from '../shared/three-sixty-slider/three-sixty-config.model';
import { ThreeSixtySliderComponent } from '../shared/three-sixty-slider/three-sixty-slider.component';
import { WebpsupportService } from '../shared/three-sixty-slider/webpsupport.service';

export class SnapFrameDetail {
  frame: number;
  display: boolean;
}

@Component({
  selector: 'app-cube-main',
  templateUrl: './cube-main.component.html',
  styleUrls: ['./cube-main.component.scss'],
})
export class CubeMainComponent implements OnInit, OnDestroy {
  @ViewChild('slider', { static: false }) slider: ThreeSixtySliderComponent;

  // instructions
  showInstructions: boolean = true;

  // event subscriptions
  webp_sub: Subscription;
  api_sub: Subscription;

  CONST = CONST;

  ext: string;

  //intitializers
  tested: boolean = false;
  config_ready: boolean = false;
  images_loaded: boolean = false;

  // slider config stuff
  slider_preload_percentage: number = 0;
  current_frame: number = 0;
  can_drag: boolean = true;
  animating: boolean = false;
  dragging: boolean = false;

  config?: ThreeSixtyConfig = undefined;

  default_config: ThreeSixtyConfig = {
    display_mode: DisplayMode.Cover,
    image_count_x: 36,
    image_count_y: 1,
    image_count_y_start: 1,
    image_count_x_start: 0,
    image_width: 1920,
    image_height: 1920,
    image_path_prefix: '',
    image_filename_prefix: '',
    image_filename_extension: '.webp',
    webp_fallback: '.png',
    single_numbering: false,
    startindex_y: 0,
    startindex_x: 0,
    x_speed: 0.5,
    y_speed: 1,
    x_wrap: true,
    y_wrap: false,
    allow_zoom: true,
    zoom_max: 3,
    autoplay_speed: 15,
    leading_zeroes: false,
    number_string_length_leading_zeros: 3,
    pauses: [],
  };

  // custom webpro features
  current_project: Project = undefined;
  //current_snapframes: SnapFrameDetail[] = [];
  sizingstyle: object;

  constructor(
    private route: ActivatedRoute,
    private webp: WebpsupportService,
    private http: HttpClient,
    private api: LetinkApiService
  ) {
    webp.test();
    this.webp_sub = webp.onTested.subscribe(() => {
      this.onTested();
    });

    this.api_sub = this.api.onReceiveEvent.subscribe((eventName) => {
      this.onReceiveEvent(eventName);
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((map) => {
      if (map.has('proj')) {
        const proj = map.get('proj');

        this.http
          .get<ProjectList>('./data/projects.json')
          .subscribe((result) => {
            const project = result.projects.find((x) => x.urlID == proj);
            if (project != undefined) {
              this.initProject(project);
            } else {
              console.log(
                `ERROR: project \'${proj}\' is not found in the project list!`
              );
            }
          });
      } else {
        console.log('ERROR: no project is provided in URL!');
      }
    });
  }

  ngOnDestroy() {
    if (this.webp_sub != undefined) this.webp_sub.unsubscribe();

    if (this.api_sub != undefined) this.api_sub.unsubscribe();
  }

  onTested() {
    this.tested = true;
  }

  onReceiveEvent(event: LetinkEvent) {
    switch (event.eventName) {
      case RECEIVE_EVENT_NEXT:
        //this.nextTriggerFrame();
        break;

      case RECEIVE_EVENT_PREVIOUS:
        //this.previousTriggerFrame();
        break;

      case RECIEVE_ANIMATE_TOWARDS:
        const ev = <AnimateEvent>event;
        this.slider.animateTowards(ev.frame, 0, ev.direction);
        break;
    }
  }

  determineAlternativeOVerlayStates() {
    if (!this.images_loaded) return;

    // this.current_snapframes.forEach((frame) => {
    //   const display =
    //     frame.frame == this.current_frame && !this.animating && !this.dragging;
    //   if (display != frame.display) {
    //     //changed state!
    //     frame.display = display;

    //     this.api.sendFrameState(frame.frame, display);
    //   }
    // });
  }

  initProject(project: Project) {
    // console.log("loading project");
    // console.log(project);

    this.current_project = project;

    // this.current_snapframes = [];
    // this.current_project.snapFrames.forEach((el) => {
    //   this.current_snapframes.push({ frame: el, display: false });
    // });

    const config = <ThreeSixtyConfig>(
      JSON.parse(JSON.stringify(this.default_config))
    );

    config.image_path_prefix = './data/' + project.folder;
    config.image_filename_prefix = project.image_filename_prefix;
    config.image_height = project.image_h;

    config.image_count_x = project.imageCountX;
    config.image_count_y = project.imageCountY;

    config.startindex_x = project.startFrameX;
    config.startindex_y = project.startFrameY;

    config.display_mode = project.display_mode;
    config.autoplay_speed = CONST.animateFPS;
    config.webp_fallback = project.webp_fallback;

    this.ext = this.webp.isSupported()
      ? '.webp'
      : this.current_project.webp_fallback;
    this.slider.applyConfig(config);

    this.config_ready = true;
  }

  onImagesLoaded(loaded: boolean) {
    this.images_loaded = loaded;
    this.determineAlternativeOVerlayStates();

    setTimeout(() => {
      this.api.sendReadyEvent();
      this.determineAlternativeOVerlayStates();
    }, 500);
  }

  startFrame: number = -1;
  onFrameChange(frame: number) {
    this.current_frame = frame;
    this.determineAlternativeOVerlayStates();

    this.api.sendRotationChange(
      //this.current_frame / (this.current_project.imageCount - 1)
      this.current_frame / (this.current_project.imageCountX - 1)
    );

    if (this.startFrame < 0) this.startFrame = frame;
    if (this.showInstructions && frame != this.startFrame)
      this.showInstructions = false;
  }

  onDownChange(down: boolean) {
    this.dragging = down;
    this.determineAlternativeOVerlayStates();
    //this.showInstructions = false;

    // if (this.dragging == false) {
    //   const action = this.fetchNearestTriggerframe(this.current_frame);

    //   if (this.current_frame != action.frame)
    //     this.slider.animateTowards(action.frame, 0, action.direction);
    // }
  }

  onAnimatingChange(state: boolean) {
    // console.log("animating state change");
    // console.log(state);
    this.animating = state;
    this.determineAlternativeOVerlayStates();

    this.api.sendAnimationStateEvent(state);
  }

  // nextTriggerFrame() {
  //   const currentTriggerframe = this.fetchNearestTriggerframe(
  //     this.current_frame
  //   );
  //   let currentIndex = this.current_project.snapFrames.indexOf(
  //     currentTriggerframe.frame
  //   );

  //   currentIndex++;

  //   if (currentIndex >= this.current_project.snapFrames.length)
  //     currentIndex = 0;

  //   const newFrame = this.current_project.snapFrames[currentIndex];

  //   this.slider.animateTowards(newFrame, 0, 1);
  // }

  // previousTriggerFrame() {
  //   const currentTriggerframe = this.fetchNearestTriggerframe(
  //     this.current_frame
  //   );
  //   let currentIndex = this.current_project.snapFrames.indexOf(
  //     currentTriggerframe.frame
  //   );

  //   currentIndex--;

  //   if (currentIndex < 0)
  //     currentIndex = this.current_project.snapFrames.length - 1;

  //   const newFrame = this.current_project.snapFrames[currentIndex];

  //   this.slider.animateTowards(newFrame, 0, -1);
  // }

  // fetchNearestTriggerframe(currentFrame: number): FrameAction {
  //   let distance: number = 999;
  //   let x: number = -1;
  //   let speed = 1;

  //   this.current_project.snapFrames.forEach((snapframe) => {
  //     const dist_forward = snapframe - currentFrame;
  //     const dist_wrapped_forward =
  //       this.current_project.imageCount - currentFrame + snapframe;
  //     const dist_wrapped_backward =
  //       currentFrame + (this.current_project.imageCount - snapframe);

  //     if (Math.abs(dist_forward) < distance) {
  //       x = snapframe;
  //       distance = Math.abs(dist_forward);
  //       speed = dist_forward > 0 ? 1 : -1;
  //     }

  //     if (Math.abs(dist_wrapped_forward) < distance) {
  //       x = snapframe;
  //       distance = Math.abs(dist_wrapped_forward);
  //       speed = 1;
  //     }

  //     if (Math.abs(dist_wrapped_backward) < distance) {
  //       x = snapframe;
  //       distance = Math.abs(dist_wrapped_backward);
  //       speed = -1;
  //     }
  //   });
  //   return { frame: x, direction: speed };
  // }

  onSizeCalc(size: ContainerMeasurements) {
    this.sizingstyle = {
      width: size.width,
      height: size.height,
      top: size.top,
      left: size.left,
    };
  }
}

export interface FrameAction {
  frame: number;
  direction: number;
}
