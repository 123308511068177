<svg fill="none" viewBox="0 0 24 24" [ngStyle]="{ 'width': size, 'height': size}">
  <!-- <defs>
        <filter id="shadow">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur" />
          <feFlood flood-color="#000000" flood-opacity="0.1" result="offsetColor" />
          <feComposite in="offsetColor" in2="blur" operator="in" result="offsetBlur" />
        </filter>
      </defs> -->

  <circle cx="12" cy="12" r="10" fill="none" stroke-width="3" stroke-miterlimit="10" [ngStyle]="{'stroke':color}"
    filter="url(#shadow)"></circle>
  <circle cx="12" cy="12" r="10" fill="none" stroke-width="3" stroke-miterlimit="10" [ngStyle]="{'stroke':color}">
  </circle>
</svg>