import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

export const RECIEVE_ANIMATE_TOWARDS = "animateTowardsFrame";
export const RECEIVE_EVENT_PREVIOUS = "previousTriggerFrame";
export const RECEIVE_EVENT_NEXT = "nextTriggerFrame";

export const SEND_EVENT_TRIGGERFRAME = "triggerFrame";
export const SEND_EVENT_PERCENTAGE = "rotationPercentage";
export const SEND_EVENT_READY = "appReady";
export const SEND_EVENT_ANIMATIONSTATE = "animationStateChange";

export interface LetinkEvent {
  eventName: string;
}

export interface TriggerFrameEvent extends LetinkEvent {
  frame: number;
  state: boolean;
}

export interface RotationChangeEvent extends LetinkEvent {
  percentage: number;
}

export interface AnimateEvent extends LetinkEvent {
  frame: number;
  direction: number;
}

export interface AnimationStateChangeEvent extends LetinkEvent {
  state: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class LetinkApiService {

  public onReceiveEvent: ReplaySubject<LetinkEvent> = new ReplaySubject<LetinkEvent>();

  constructor() { }

  public init() {
    console.log("API: intialize - subscribe to messages from parent");
    window.addEventListener("message", this.onReceiveMessage.bind(this));
  }

  private onReceiveMessage($event: MessageEvent) {
    const data: LetinkEvent = <LetinkEvent>($event.data);

    if (data.hasOwnProperty("eventName")) {
      // console.log("API: received message from parent");
      // console.log(data);
      //is a letink event
      this.onReceiveEvent.next(data);
    }
  }

  public sendFrameState(frame: number, state: boolean) {

    const parent = window.parent;

    if (parent != undefined) {

      const data: TriggerFrameEvent = {
        eventName: SEND_EVENT_TRIGGERFRAME,
        frame: frame,
        state: state
      };

      console.log("API: send trigger frame event");

      parent.postMessage(data, "*");

    } else {
      console.log("can't find parent window - not embedded?");
    }
  }

  public sendRotationChange(percentage: number) {
    const parent = window.parent;

    if (parent != undefined) {

      const data: RotationChangeEvent = {
        eventName: SEND_EVENT_PERCENTAGE,
        percentage: percentage
      };

      parent.postMessage(data, "*");

    } else {
      console.log("can't find parent window - not embedded?");
    }
  }

  public sendAnimationStateEvent(state: boolean) {

    const parent = window.parent;

    if (parent != undefined) {

      const data: AnimationStateChangeEvent = {
        eventName: SEND_EVENT_ANIMATIONSTATE,
        state: state
      };

      parent.postMessage(data, "*");

    } else {
      console.log("can't find parent window - not embedded?");
    }
  }

  public sendReadyEvent() {

    const parent = window.parent;

    if (parent != undefined) {

      const data: LetinkEvent = {
        eventName: SEND_EVENT_READY,
      };
      console.log("API: send app ready event");

      parent.postMessage(data, "*");

    } else {
      console.log("can't find parent window - not embedded?");
    }
  }
}
