import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'svg-loading',
  templateUrl: './svgloading.component.html',
  styleUrls: ['./svgloading.component.scss']
})
export class SvgLoadingComponent implements OnInit {

  @Input() size: string = "24px";
  @Input() color : string = "white"

  constructor() { }

  ngOnInit(): void {
  }

}
