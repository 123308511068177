import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CubeMainComponent } from './cube-main/cube-main.component';
import { SvgLoadingComponent } from './shared/svgloading/svgloading.component';
import { ThreeSixtySliderComponent } from './shared/three-sixty-slider/three-sixty-slider.component';
import { WebpsupportService } from './shared/three-sixty-slider/webpsupport.service';
import { FadeVisibleDirective } from './directives/fade-visible.directive';
import { LetinkApiService } from './services/letink-api.service';

@NgModule({
  declarations: [
    AppComponent,
    CubeMainComponent,
    SvgLoadingComponent,
    ThreeSixtySliderComponent,
    FadeVisibleDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [
    WebpsupportService,
    LetinkApiService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
