
export enum DisplayMode { Contain = 0, Cover = 1 }

export class ThreeSixtyConfig {
    display_mode: DisplayMode;

    image_width: number;
    image_height: number;
    image_count_x: number;
    image_count_y: number;
    image_count_x_start: number = 0;
    image_count_y_start: number = 0;

    //naming convention: prefix_y_x
    image_path_prefix: string;
    image_filename_prefix: string;
    image_filename_extension: string = ".png";
    single_numbering: boolean = false;
    leading_zeroes: boolean = false;
    number_string_length_leading_zeros: number = 3;

    startindex_x: number;
    startindex_y: number;

    //percentage of the full range that is slided when dragging the full container
    //eg: user drags from top container to bottom container with speed 0.5 --> y index changes with 0.5 * image_count_y
    x_speed: number;
    y_speed: number;

    x_wrap: boolean = true;
    y_wrap: boolean = false;

    //zooming
    allow_zoom: boolean = false;
    zoom_max: number = 3;

    //webp
    webp_fallback: string = ".png";
    autoplay_speed: number = 15;

    pauses: number[] = [];
}

//used for other containers
//use the resizedTo to fetch this class
//then apply it to anything that must match this containers size
export class ContainerMeasurements {
    width: string;
    height: string;
    left: string;
    top: string;
}