<!-- three sixty slider container -->

<div class="slider-root full-frame hidden" [appFadeVisible]="images_loaded">
  <!-- 360 slider -->
  <letink-three-sixty-slider
    class="full-frame"
    [ngClass]="{ grab: !dragging, grabbing: dragging }"
    container-class="contain"
    #slider
    [config]="config"
    [autoInit]="false"
    (onLoadingPercentageChange)="slider_preload_percentage = $event"
    (onImagesLoaded)="onImagesLoaded($event)"
    (x)="onFrameChange($event)"
    (onDownStateChange)="onDownChange($event)"
    (onAnimating)="onAnimatingChange($event)"
    (onRecalculateSizing)="onSizeCalc($event)"
  >
  </letink-three-sixty-slider>

  <img
    class="instruction-icons"
    src="./data/360-degrees-icon.png"
    *ngIf="showInstructions"
  />
</div>

<!-- alt images overlay -->
<!-- <div class="full-frame no-overflow non-blocking"
    *ngIf="images_loaded && current_project != undefined && current_project.hasAlternativeImages">
    <div class="overlay non-blocking" [ngStyle]="sizingstyle">

        <img *ngFor="let p of current_project.snapFrames" class="hidden"
            [src]="'./data/' + current_project.folder + p + current_project.alternativeSuffix + ext"
            [appFadeVisible]="dragging == false && animating == false && current_frame == p"
            [fade-in-time]="CONST.fadeInTime" [fade-out-time]="CONST.fadeOutTime" />
    </div>

</div> -->

<!-- *ngIf="!images_loaded" -->
<div class="loading" [appFadeVisible]="!images_loaded">
  <svg-loading></svg-loading>
  <span>
    {{ slider_preload_percentage | percent }}
  </span>
</div>
