import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CubeMainComponent } from './cube-main/cube-main.component';

const routes: Routes = [
  { path: ':proj', component: CubeMainComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
