import { Component, OnInit } from '@angular/core';
import { LetinkApiService } from './services/letink-api.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styles: ['']
})
export class AppComponent {

  constructor(private api: LetinkApiService) {
    api.init();
  }
}
